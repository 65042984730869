<template>
	<!--交付场地-->
	<mainBox>
		<div>
			<div class="partName"><span>1.场地相关信息</span></div>
			<div class="infoWarp">
				<TForm class='basisBox' autoCheck ref="htmlFrom" :model="pageParams"  :formlist="formlist" label-width="108px" labelPosition='left'>
					<template slot="oTypeArrs" slot-scope="scope">
						<!--pageParams[scope.current.keys]-->
					</template>
				</TForm>
			</div>
		</div>
		<div>
			<div class="partName"><span>2.场地交付文件</span></div>
			<div >
				<div class="jfTips">上传场地交付相关文件 <span class="ft12">例：《房屋交付确认书》、《家具交付确认书》</span></div>
				<div class="border1x">
					<div class="w50">
						<TForm autoCheck ref="htmlFrom2" :model="pageParams"  :formlist="formlist2"  label-width="158px" ></TForm>
					</div>
				</div>
			</div>
		</div>
		<div class='bdItem'>
			<div class="partName"><span>3.上传现场现状图</span></div>
			<TForm autoCheck ref="img1" :model="pageParams"  :formlist="imgsArr1"  label-width="158px" ></TForm>
		 	<TForm autoCheck ref="img2" :model="pageParams"  :formlist="imgsArr2"  label-width="158px" ></TForm>
			
		</div>
		
		<div class="btmBtn" >
  		<el-button type="primary" @click='validateFroms' > 保存提交</el-button>
    </div>
		
		
		
	</mainBox>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default{
		computed: {},
		components: {TForm},
		props: {},
		watch: {},
		data() {
			return {
				pageParams: {
					
				},
				urlDatas:{},
				formlist: [
					{name: '场地位置',keys: 'positionName',value: '',type: 'input',isMust:true,flex:8,disabled:true},
					{name: '面积（m3）',keys: 'rentArea',value: '',type: 'input',isMust:true,flex:8,disabled:true},
					{name: '接收人',keys: 'receivingPeople',value: '',type: 'input',isMust:true,flex:8},
					{name: '交付日期',keys: 'actualDeliverDate',value: '',type: 'time',isMust:true,flex:8,format:'timestamp'},
					{name: '场地接收单位',keys: 'receivingUnit',value: '',type: 'input',isMust:true,flex:8},
					{name: '场地现状',keys: 'siteStatus',value: '',type: 'select',isMust:true,flex:8,options:[
						{name:'毛坯',code:'1'},
						{name:'简装',code:'2'},
						{name:'精装',code:'3'},
						{name:'带家具',code:'4'},
						{name:'无家具',code:'5'},
					]},
					{name: '客服部经办人',keys: 'operatorUserId',value: '',type: 'employe',isMust:true,flex:8},
				],
				formlist2:[
					{name: '场地交付文件',keys: 'cdjfFiles',value: [],type: 'upload2',labelHide:true},
				],
				imgsArr1:[
					{name: '地面现状情况图片/视频',keys: 'floorStatusResource',imgWidth:'80px',imgHeight:'80px',value:[],type: 'imgVideo',allowAdd:true,tips:'上传现场图片或视频(最多9项)',tipleft:'218px',flex:12},
					{name: '天花板情况图片/视频',keys: 'ceilingStatusResource',imgWidth:'80px',imgHeight:'80px',value:[],type: 'imgVideo',allowAdd:true,tips:'上传现场图片或视频(最多9项)',tipleft:'218px',flex:12},
				],
				imgsArr2:[
					{name: '主体墙面情况图片/视频',keys: 'metopeStatusResource',imgWidth:'80px',imgHeight:'80px',value:[],type: 'imgVideo',allowAdd:true,tips:'上传现场图片或视频(最多9项)',tipleft:'218px',flex:12},
					{name: '其他位置情况图片/视频',keys: 'otherResource',imgWidth:'80px',imgHeight:'80px',value:[],type: 'imgVideo',allowAdd:true,tips:'上传现场图片或视频(最多9项)',tipleft:'218px',flex:12},
				],
			};
		},
		created() {
			let query=this.$route.query;
			const {rentOrderId,rentArea,positionName} = query;
			if(query)	this.urlDatas=JSON.parse(JSON.stringify(query))
			this.initNowHtml();
			this.pageParams.rentArea=rentArea;
			this.pageParams.positionName=positionName;
			
			this.$store.dispatch('getEmployeeDatas');//获取所有部门人员
			
		},
		mounted() {},
		methods: {
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
				})
			},
			async getFromDatas(){
//				console.log(88888888,JSON.parse(JSON.stringify(this.pageParams)))
				let params={
					...this.urlDatas,
					...this.pageParams,
					enterpriseDocumentUploadVO:{
						documentType:'contract_type',
						enterpriseId:this.urlDatas.enterpriseId,
						files:this.pageParams.cdjfFiles,
					}
				}
				console.log(88888888,JSON.parse(JSON.stringify(params)))
				delete params.cdjfFiles;
//				if(params.actualDeliverDate) params.actualDeliverDate=new Date(params.actualDeliverDate).getTime();
				let res = await this.ApiHttp('/organization/enterprise/rent/deliver/createDeliverInfo',params,'post');
				if(res){
					this.$router.go(-1);
				}
				
				
			},
			async initNowHtml() {
				this.resetFormItVals();
				
			},
			resetFormItVals() {
				//重置表单的值  父组件有调用
				for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.pageParams, item.keys, item.value);
				}
				for(let item of this.formlist2) { //初始化赋值我的表单数据
					this.$set(this.pageParams, item.keys, item.value);
				}
				for(let item of this.imgsArr1) { //初始化赋值我的表单数据
					this.$set(this.pageParams, item.keys, item.value);
				}
				for(let item of this.imgsArr2) { //初始化赋值我的表单数据
					this.$set(this.pageParams, item.keys, item.value);
				}
			},
		},
		destroyed() {},
	}
</script>

<style lang="scss">
	.infoWarp{
		.el-form-item{
			width: 90%;
		}
	}
	.partName{
		
		line-height: 32px;
		padding-top: 10px;
		margin-bottom: 18px;
		border-bottom: 2px solid #DCDFE5;
		span{
			display: inline-block;
			border-bottom: 2px solid #303133;
			color: #303133;
			font-weight: bold;
			font-size: 14px;
			position: relative;
			top: 2px;
		}
	}
	.jfTips{
		margin-top: 4px;
		padding-bottom: 24px;
		color: #4E5969;
		.ft12{
			margin-left: 8px;
			color: #909399;
			font-size: 12px;
		}
	}
	.border1x{
		border: 1px solid #DCDFE5;
		padding: 24px;
		.w50{
			width: 50%;
		}
	}
	.bdItem .lineh58{
		.t_row{
			border: 1px solid #DCDFE5;
			padding: 28px 24px;
			width: 88%;
			margin: 28px 0;
			.tipTx{
				top:28px !important;
			}
		}
		
		
	}
	.lineh58:nth-child(even){
			.t_row{
			margin-left: 12%;
		}
		}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mainBox", [
    _c("div", [
      _c("div", { staticClass: "partName" }, [
        _c("span", [_vm._v("1.场地相关信息")]),
      ]),
      _c(
        "div",
        { staticClass: "infoWarp" },
        [
          _c("TForm", {
            ref: "htmlFrom",
            staticClass: "basisBox",
            attrs: {
              autoCheck: "",
              model: _vm.pageParams,
              formlist: _vm.formlist,
              "label-width": "108px",
              labelPosition: "left",
            },
            scopedSlots: _vm._u([
              {
                key: "oTypeArrs",
                fn: function (scope) {
                  return undefined
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "partName" }, [
        _c("span", [_vm._v("2.场地交付文件")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "jfTips" }, [
          _vm._v("上传场地交付相关文件 "),
          _c("span", { staticClass: "ft12" }, [
            _vm._v("例：《房屋交付确认书》、《家具交付确认书》"),
          ]),
        ]),
        _c("div", { staticClass: "border1x" }, [
          _c(
            "div",
            { staticClass: "w50" },
            [
              _c("TForm", {
                ref: "htmlFrom2",
                attrs: {
                  autoCheck: "",
                  model: _vm.pageParams,
                  formlist: _vm.formlist2,
                  "label-width": "158px",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "bdItem" },
      [
        _c("div", { staticClass: "partName" }, [
          _c("span", [_vm._v("3.上传现场现状图")]),
        ]),
        _c("TForm", {
          ref: "img1",
          attrs: {
            autoCheck: "",
            model: _vm.pageParams,
            formlist: _vm.imgsArr1,
            "label-width": "158px",
          },
        }),
        _c("TForm", {
          ref: "img2",
          attrs: {
            autoCheck: "",
            model: _vm.pageParams,
            formlist: _vm.imgsArr2,
            "label-width": "158px",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btmBtn" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.validateFroms } },
          [_vm._v(" 保存提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }